import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import NavbarPage from './Navbar/NavBar';

export default class LearnMore extends Component {
    constructor(props) {
        super(props);
        this.state = {
          navItems: [
            { id: 1, idnm: "home", navheading: "Home" },
            { id: 2, idnm: "about", navheading: "About" },
            { id: 3, idnm: "services", navheading: "Services" },
            { id: 4, idnm: "features", navheading: "Features" },
            // { id: 5, idnm: "pricing", navheading: "Pricing" },
            // { id: 6, idnm: "clients", navheading: "Clients" },
            // { id: 7, idnm: "blog", navheading: "Blog" },
            { id: 7, idnm: "contact", navheading: "Contact Us" },
          ],
          // pos: document.documentElement.scrollTop,
          imglight: true,
          navClass: "navbar-light navbar-custom sticky sticky-darknav-sticky navbar-light navbar-custom sticky sticky-dark",
        };
      }
    
      componentDidMount() {
        window.addEventListener("scroll", this.scrollNavigation, true);
      }
    
      componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation, true);
      }
    
      scrollNavigation = () => {
        var scrollup = document.documentElement.scrollTop;
        // if (scrollup > this.state.pos) {
          this.setState({ navClass: "nav-sticky navbar-light navbar-custom sticky sticky-dark", imglight: false });
        // } else {
          // this.setState({ navClass: "navbar-light navbar-custom sticky sticky-dark", imglight: true });
        // }
      };
    
      //set preloader div
      PreLoader = () => {
        return (
          <div id="preloader">
            <div id="status">
              <div className="sk-cube-grid">
                <div className="sk-cube sk-cube1"></div>
                <div className="sk-cube sk-cube2"></div>
                <div className="sk-cube sk-cube3"></div>
                <div className="sk-cube sk-cube4"></div>
                <div className="sk-cube sk-cube5"></div>
                <div className="sk-cube sk-cube6"></div>
                <div className="sk-cube sk-cube7"></div>
                <div className="sk-cube sk-cube8"></div>
                <div className="sk-cube sk-cube9"></div>
              </div>
            </div>
          </div>
        );
      };
    
    render() {
        return (
            <div>
                 <NavbarPage
            navItems={this.state.navItems}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
          />
                <Container style={{position:'absolute',top:'25%'}}>
                    <Row>
                        <Col lg={12}>
                            <div className="title-heading mb-5">
                                <h3 className="text-dark mb-1 fw-light text-uppercase">Additional Features</h3>
                                <div className="title-border-simple position-relative"></div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="align-items-center">
                        <Col lg={6}>
                            <div className="features-content">
                                <div className="features-icon">
                                    <i className="pe-7s-science text-success"></i>
                                </div>
                                <h4 className="fw-normal text-dark mb-3 mt-4">Marketing Performance</h4>
                                <p className="text-muted f-14">Itaque earum rerum hic tenetur sapiente delectut reiciendis
                                    voluptatibus perspiciatis unde omnis iste natus error sit maiores alias consequatur
                                    perferendisthat doloribus asperiores repellat.</p>
                                <p className="text-muted f-14">Nam libero tempore cum soluta nobis eligendi optio cumque nihil
                                    impedit minusidquod maxime placeat facere possimus.</p>
                                <p className="mb-0 text-uppercase f-13"><Link to="#" className="text-success">learn more <span
                                    className="right-arrow ms-1">&#x21FE;</span></Link></p>
                            </div>
                        </Col>

                        <Col lg={6}>
                            {/* <div className="features-img mt-32">
                                    <img src={features} alt="" className="img-fluid mx-auto d-block" />
                                </div> */}
                        </Col>
                    </Row>

                </Container>
            </div>
        )
    }
}