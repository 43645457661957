import React, { Component, useState } from "react";
import {
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Container,
  Collapse,
  Row,
  Col,
} from "reactstrap";
// Import Logo
import logodark from "../../assets/images/zooki.png";
import logolight from "../../assets/images/zooki.png";
import logo from "../../assets/images/sahl LOGO final-15.svg";
import { Popconfirm, message, Modal, Radio, Dropdown, Button, Space } from "antd";
import { DownOutlined } from '@ant-design/icons';
import { useTranslation, withNamespaces, withTranslation } from 'react-i18next';
import i18n from "../../i18n";
//import icon
import FeatherIcon from "feather-icons-react";

import ScrollspyNav from "./Scrollspy";

class NavbarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
      isModalVisible: false
    };
  }

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  changeLanguage = (lng) => {
    console.log(lng.target.value, 'lng.target.value');
    i18n.changeLanguage(lng.target.value);
  }


  render() {

    const { t } = this.props;

    const items = [
      {
        label: 'English',
        key: 'en',
      },
      {
        label: 'Arabic',
        key: 'ar',
      }
    ];

    const showModal = () => {
      this.setState({ isModalVisible: true })
    };
    const handleOk = () => {
      this.setState({ isModalVisible: false })
    };
    const handleCancel = () => {
      this.setState({ isModalVisible: false })
    };

    const onClick = ({ key }) => {
      i18n.changeLanguage(key);
    };

    var targetId = this.props.navItems.map((item) => {
      return item.idnm;
    });

    console.log(this.state.isModalVisible, 'isModalVisible');

    return (
      <React.Fragment>
        {/* Navbar Start */}
        <nav
          className={"navbar navbar-expand-lg py-0 fixed-top sticky " + this.props.navClass}
        >
          <Container>
            {/* LOGO */}
            <NavbarBrand className="logo" href="/">
              {this.props.imglight === true ? (
                <img src={logo} alt="" style={{ width: '200px' }} />
              ) : (
                <img src={logo} alt="" width={180} style={{ width: '200px' }} />
              )}
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle}>
              <i>
                <FeatherIcon icon="menu" />
              </i>
            </NavbarToggler>
            <Collapse
              id="navbarCollapse"
              isOpen={this.state.isOpenMenu}
              className=" navbar-collapse"
            >
              <ScrollspyNav
                scrollTargetIds={targetId}
                scrollDuration="800"
                headerBackground="true"
                activeNavClass="active"
                className="navbar-collapse"
              >
                <Nav navbar className="ms-auto navbar-center" id="mySidenav">
                  {this.props.navItems.map((item, key) => (
                    <NavItem
                      key={key}
                      className={item.navheading === "Home" ? "active" : ""}
                    >
                      <NavLink
                        className={item.navheading === "Home" ? "active" : ""}
                        href={"#" + item.idnm}
                        style={{ fontSize: '12px' }}
                      >
                        {(item.navheading === 'Home' ? t('nav_home') : (item.navheading === 'About' ? t('nav_about') : (item.navheading === 'Services' ? t('nav_service') : (item.navheading === 'Features' ? t('nav_feature') : (item.navheading === 'Contact Us' ? t('nav_contact') : item.navheading)))))}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
              </ScrollspyNav>
            </Collapse>
          </Container>
          <Dropdown
            
            menu={{
              items,
              onClick,
            }}
          >
            <a style={{marginRight:'20px',fontSize:'15px'}} onClick={(e) => e.preventDefault()}>
              <Space>
                {t('Language')}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>
          {/* <Radio.Group name="radiogroup" defaultValue="en" onChange={this.changeLanguage}>
            <Radio value="en">English</Radio>
            <Radio value="ar">Arabic</Radio>
          </Radio.Group> */}
        </nav >
      </React.Fragment >
    );
  }
}
// }
export default withTranslation()(NavbarPage);
